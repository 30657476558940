<template>
    <!--====================  video cta area ====================-->
    <div class="video-cta-area section-space--inner--120">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="video-cta-content">
                        <h4 class="video-cta-content__small-title">{{ data.sectionSubTitle }}</h4>
                        <h3 class="video-cta-content__title">{{ data.sectionTitle }}</h3>
                        <p class="video-cta-content__text">{{ data.desc }}</p>
                        <router-link to="/contact" class="ht-btn ht-btn--round">联系我们</router-link>
                    </div>
                </div>
                <div class="col-lg-5 offset-lg-1 col-md-12">
                    <div class="cta-video-image">
                        <div class="video-popup">
                            <silentbox-single :src="data.youtubeLink">
                                <div class="cta-video-image__image">
                                    <img :src="data.image" class="img-fluid" alt="image">
                                </div>
                                <div class="cta-video-image__icon">
                                    <i class="ion-ios-play"></i>
                                </div>
                            </silentbox-single>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--====================  End of video cta area  ====================-->
</template>

<script>
    import data from '../../data/about.json'
    export default {
        data () {
            return {
                data
            }
        }
    };
</script>